import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ProjectHelse = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Desarrollo web a medida con Drupal" description="Estamos especializados en el desarrollo a medida de sitios web con Drupal, tanto para ecommerce como para sitios web corporativos."/>
  <section className="jumbotron bg-transparent" id="header">
    <div className="container">
      <div className="row align-items-center text-center">
        <h1 className="font-weight-bold display-4 pl-0 p-1 col-md-9 offset-md-2 col-sm-12">Especialistas en desarrollos Drupal a medida</h1>
        <p className="lead mb-4 col-md-9 offset-md-2 col-sm-12">Realizamos desarrollos a medida en la última versión de Drupal, ajustándonos a las especificaciones del cliente. Nuestros desarrollos son totalmente modulares y escalables, por lo que nos gusta proponer a nuestros clientes el desarrollo por fases para obtener el mayor valor posible de nuestro trabajo.</p>
      </div>
    </div>
  </section>
  <section className=" pb-5 pt-5 bg-light text-left" id="features">
    <div className="container">
      <div className="row text-left">
        <div className="col col-md-9 offset-md-2 col-sm-12">
          <h2>
            <strong className="text-left">Tipos de desarrollos a medida</strong>
          </h2>
          <p className="lead pt-4">Desarrollamos muchas tipologías de proyectos a medida Drupal, desde webs corporativas sencillas a complejas intranets de gestión. Estas son algunas de las tipologías de proyecto más comunes.</p>
          <h3 className="pt-2">Webs corporativas</h3>
          <p className="lead">Desarrollamos muchas tipologías de proyectos a medida Drupal, desde webs corporativas sencillas a complejas intranets de gestión. Estas son algunas de las tipologías de proyecto más comunes.</p>
          <h3 className="pt-2">Aplicaciones de gestión</h3>
          <p className="lead">Desarrollamos muchas tipologías de proyectos a medida Drupal, desde webs corporativas sencillas a complejas intranets de gestión. Estas son algunas de las tipologías de proyecto más comunes.</p>
          <h3 className="pt-2">Single Page Applications</h3>
          <p className="lead">Desarrollamos muchas tipologías de proyectos a medida Drupal, desde webs corporativas sencillas a complejas intranets de gestión. Estas son algunas de las tipologías de proyecto más comunes.</p>
        </div>
      </div>
    </div>
  </section>

    </Layout>
  )
}

export default ProjectHelse
